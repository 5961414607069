
/* mobile */
@media only screen and (max-width: 1200px) {
  .third-section .third-section-inside .left-part .title h1
  {
    width: 75%;
  }
}
@media only screen and (max-width: 1150px) {
  .first-section-inside .left-content span
  {
    font-size: 35px;
  }
  .first-work-content,
  .second-work-content  
  {
    padding: 0;
  }
}
@media only screen and (max-width: 890px) {
  .third-section .third-section-inside .grid-2.right-part
  {
    max-height: 280px;
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    position: relative;
    float: left;
    width: 100%;
  }
  .third-section .third-section-inside .right-part .testimonials
  {
    float: left;
    width: 100%;
    min-width: 100%;
    margin: 0px 10px;
  }
  .third-section .third-section-inside .right-part .testimonials:first-child
  {
    margin-left: 0px;
  }
  .header .header-inside .nav
  {
    padding-left: 50px;
  }
  .first-section-inside .left-content
  {
    width: 100%;
    padding: 50px;
    height: auto;
    margin: 20px 0px !important;
  }
  .first-section-inside .right-video
  {
    width: 100%;
  }
  .grid-2.right-part
  {
    float: left;
    margin-left: 0px !important;
  }
  .fifth-section .fifth-section-inside .title
  {
    width: 90%;
  }
  .footer .footer-inside .left-part,
  .footer .footer-inside .right-part
  {
    width: 100%;
  }
  .footer-services.grid-3
  {
    width: calc(calc(100% / 4) - 15px);
  }
  .fourth-section .fourth-section-inside .title
  {
    margin-top: 0px;
    position: relative;
  }
  .fourth-section .fourth-section-inside .title h1
  {
    width: 100%;
  }
  .flex-control-thumbs
  {
    padding: 0px !important;
  }
  .community .first-section .first-section-inside .left-content h1
  {
    width: 100%;
  }
  .community .first-section .first-section-inside .text-community
  {
    padding: 0px 50px 50px 50px;
  }
  .first-section.about-first .left-content,
  .first-section.about-first .right-video,
  .first-section.about-second .left-content,
  .first-section.about-second .right-video,
  .first-section.about-third .left-content,
  .first-section.about-third .right-video
  {
    width: 100%;
  }
  .first-section.about-second .first-section-inside
  {
    flex-direction: column;
  }
  .first-section.about-third .first-section-inside .right-video
  {
    padding: 0px 50px 50px 50px;
  }
  .first-section.about-third .first-section-inside .right-video span:nth-child(3),
  .first-section.about-third .first-section-inside .right-video span:nth-child(1)
  {
    display: none;
  }
  .first-section.about-first .first-section-inside .left-content span:nth-child(2),
  .first-section.about-second .first-section-inside .left-content span:nth-child(2),
  .first-section.about-third .first-section-inside .left-content span:nth-child(2)
  {
    width: 100%;
  }
  .contact .contact-form
  {
    max-height: max-content;
  }
  .contact .contact-form .left-part
  {
    width: 100%;
  }
  .contact .contact-form .right-part
  {
    width: 100%;
  }
}
@media only screen and (max-width: 700px) {
  .partners
  {
    height: auto;
  }
  .partners .img {
    width: calc(calc(100% / 3) - 40px);
  }
  .first-section-inside .right-video
  {
    height: auto;
  }
  .team-third-section .left-content h1,
  .fourth-section .fourth-section-inside .title h1,
  .fifth-section .fifth-section-inside .title h1,
  .second-section .second-section-inside .title h1,
  .third-section .third-section-inside .left-part .title h1
  {
    line-height: 30px;
    width: 100%;
    font-size: 30px;
  }
  .sixth-section .sixth-section-inside .right-part
  {
    width: 100%;
  }
  .sixth-section .sixth-section-inside .left-part
  {
    width: 100%;
    text-align: center;
  }
  .header .burger-menu
  {
    float: right;
    width: 60px;
    height: 40px;
    position: relative;
  }
  .header .burger-menu .burger-holder
  {
    float: right;
    width: 30px;
    height: 100%;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .header .burger-menu .burger-holder .burger-line
  {
    float: left;
    width: 100%;
    height: 2px;
    border-radius: 25px;
    background-color: #382062;
    position: absolute;
    transition: .5s linear all;
  }
  .header .burger-menu .burger-holder .burger-line:nth-child(1)
  {
    top: 0px;
  }
  .header .burger-menu .burger-holder .burger-line:nth-child(2)
  {
    top: 50%;
    transform: translateY(-50%);
  }
  .header .burger-menu .burger-holder .burger-line:nth-child(3)
  {
      bottom: 0px;
  }
  .header.active .burger-menu .burger-holder .burger-line:nth-child(1)
  {
    transform: translateY(calc(-50% + -1px)) rotate(-45deg);
    top: 50%;
  }
  .header.active .burger-menu .burger-holder .burger-line:nth-child(2)
  {
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      width: 0px;
  }
  .header.active .burger-menu .burger-holder .burger-line:nth-child(3)
  {
      transform: translateY(-50%) rotate(45deg);
      bottom: 50%;
  }

  .header .header-inside .nav
  {
    width: 100%;
    padding: 0px;
    height: 0px;
    overflow: hidden;
    transition: .3s linear all;
  }
  .header.active .header-inside .nav
  {
    height: 120px;
    margin-top: 20px;
  }
  .header .header-inside .nav .nav-wrapper
  {
    float: left;
    width: auto;
    height: auto;
    width: 100%;
    display: block;
  }
  .header .header-inside .nav .nav-wrapper a
  {
    float: left;
    height: auto;
    width: 100%;
    margin: 5px;
    color: #111;
    text-align: center;
    /* line-height: 2px; */
  }
  .body
  {
    margin-top: 0px;
  }
  .header,
  .header .header-inside
  {
    min-height: 50px;
    height: auto;
  }
  .header .header-inside .logo
  {
    display: block;
    left: 50%;
    margin-top: 10px;
    transform: translateX(-50%);
  }
  .about-title h1
  {
    font-size: 40px;
    margin-top: 50px;
  }
  .community .first-section .first-section-inside .text-community span
  {
    width: 100%;
  }
  .service-one,
  .service-two,
  .service-three,
  .service-four,
  .service-five
  {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .service-one.reverse,
  .service-two.reverse,
  .service-three.reverse,
  .service-four.reverse,
  .service-five.reverse
  {
    display: flex;
    flex-direction: column;
  }
  .service-one .part-one,
  .service-two .part-one,
  .service-three .part-one,
  .service-four .part-one,
  .service-five .part-one
  {
    width: 100%;
  }
  .service-one .part-two,
  .service-two .part-two,
  .service-three .part-two,
  .service-four .part-two,
  .service-five .part-two
  {
    width: 100%;
  }
  .after-title span
  {
    width: 100%;
  }
  .service-one .part-one span,
  .service-two .part-one span,
  .service-three .part-one span,
  .service-four .part-one span,
  .service-five .part-one span
  {
    text-align: center;
  }
  .service-one .part-one h1,
  .service-two .part-one h1,
  .service-three .part-one h1,
  .service-four .part-one h1,
  .service-five .part-one h1
  {
    text-align: center;
  }
  .first-work
  {
    flex-direction: column;
  }
  .first-work-content,
  .first-work-img,
  .second-work-content,
  .second-work-img
  {
    width: 100%;
  }
  .second-work
  {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 550px) {
  .fifth-section .fifth-section-inside .title
  {
    width: 100%;
  }
  .team-third-section .left-content h1,
  .fifth-section .fifth-section-inside .title h1
  {
    line-height: 40px;
    font-size: 30px;
  }
  .footer-services.grid-3
  {
    width: 100%;
  }
  .community .first-section .about-title .text span
  {
    width: 100%;
  }
  .contact .contact-form .left-part p
  {
    font-size: 25px;
  }
  .extra-content .img-extra
  {
    height: 200px;
  }
}
@media only screen and (max-width: 480px) {
  .first-section-inside .left-content
  {
    padding: 20px;
  }
  .first-section-inside .left-content span
  {
    font-size: 25px;
  }

  .first-section-inside .left-content span:nth-child(2)
  {
    font-size: 17px;
  }
  .partners .img {
    width: calc(calc(100% / 2) - 40px);
    margin: 20px 0px;
  }
  .third-section .third-section-inside .right-part .testimonials
  {
    padding: 40px 10px;
  }
  .third-section .third-section-inside .right-part .testimonials .img img
  {
    width: 80px;
    height: 80px;
  }
  .footer .footer-copyright span:last-child
  {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .footer .footer-copyright span:first-child
  {
    width: 100%;
    text-align: center;
  }
  .about-title h1
  {
    font-size: 25px;
  }
  .community .first-section .first-section-inside .text-community
  {
    padding: 20px;
  }
  .community .first-section .first-section-inside .left-content h1
  {
    font-size: 25px;
  }
  .first-section.about-third .first-section-inside .right-video
  {
    padding: 0px 20px 20px 20px
  }
}
@media only screen and (max-width: 400px) {
  .partners .img
  {
    width: 100%;
  }
  .team-third-section .left-content h1,
  .fifth-section .fifth-section-inside .title h1
  {
    line-height: 30px;
    font-size: 25px;
  }
}
