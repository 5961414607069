
	* {
		box-sizing: border-box;
		outline: 0;
		font-family: 'Montserrat', sans-serif;
		padding: 0px;
		margin: 0px;
	}

	body {
		position: relative;
		width: 100%;
		height: 100vh;
		float: left;
	}

	h1,
	h2,
	h3,
	h4 {
		margin:0;
	}

	img {
		max-width: 100%;
	}

	p {
		margin: 0;
		padding: 0;
	}

	a,
	a:focus,
	a:after,
	a:before,
	a:visited,
	a:hover {
		text-decoration: none;
		cursor: pointer;
	}


	/* Generalization of Structure */

	body {
		position: relative;
		overflow-x: hidden;
		float: left;
		width: 100%;
		height: 100%;
	}

	.content {
		overflow-x: hidden;
	}

	.container-fluid {
		padding: 0px;
		position: relative;
	}

	.container-limit {
		width: 100%;
		max-width: 1340px;
		margin: 0 auto;
	}
	.container-limit.extra-limit {
		max-width: 1440px;
	}

	.container-no-limit {
		width: 100%;
		max-width: 100%;
	}

	/* .container{
		width: 100%;
		position: relative;
	} */

	.container-color {
		background-color: #f5f3f7;
	}

	.grid
	{
		float: left;
		width: 100%;
		display: -webkit-flex;
		-webkit-align-items: flex-start;
		-moz-align-items: center;
		/* align-items: flex-start; */
		/* -webkit-justify-content: center; */
		/* justify-content: center; */
		flex-direction: row;
		-webkit-flex-direction: row;
		-moz-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-moz-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-flex-flow: row wrap;
		-moz-flex-flow: row wrap;
		flex-flow: row wrap;
		-webkit-align-content: flex-end;
		-moz-align-content: flex-end;
		align-content: flex-start;
		/* margin-left: -15px; */
	}
	.grid .grid-2
	{
		position: relative;
		float: left;
		height: auto;
		width: calc(calc(100% / 2) - 15px);
		margin-right: 15px;
		margin-bottom: 15px;
		transition: .3s linear all;
	}
	.grid .grid-2:last-child
	{
		margin-right: 0;
		margin-left: 15px;
	}
	.grid .grid-3
	{
		position: relative;
		float: left;
		height: auto;
		width: calc(calc(100% / 3) - 15px);
		margin-right: 15px;
		margin-bottom: 15px;
		transition: .3s linear all;
	}
	.grid .grid-4
	{
		position: relative;
		float: left;
		height: auto;
		width: calc(calc(100% / 4) - 15px);
		margin-right: 15px;
		margin-bottom: 15px;
		transition: .3s linear all;
	}
	.grid .grid-5
	{
		position: relative;
		float: left;
		height: auto;
		width: calc(calc(100% / 5) - 25px);
		margin-right: 25px;
		margin-bottom: 25px;
		transition: .3s linear all;
	}


	/* responsive */
	@media only screen and (max-width: 1100px) {
		.grid .grid-5
		{
			width: calc(calc(100% / 4) - 25px);
		}
	}
	@media only screen and (max-width: 990px) {
		.grid .grid-4
		{
			width: calc(calc(100% / 3) - 15px);
		}
		.grid .grid-5
		{
			width: calc(calc(100% / 3) - 25px);
		}
	}
	@media only screen and (max-width: 890px) {
		.grid .grid-3
		{
			width: calc(calc(100% / 2) - 15px);
		}
		.grid .grid-2
		{
			width: 100%;
		}
	}
	@media only screen and (max-width: 720px) {
		.grid .grid-5
		{
			width: calc(calc(100% / 2) - 25px);
		}
		.grid .grid-4
		{
			width: calc(calc(100% / 2) - 15px);
		}
	}
	@media only screen and (max-width: 570px) {
		.grid .grid-3
		{
			width: 100%;
		}
		.grid .grid-5
		{
			width: 100%;
		}
		.grid .grid-4
		{
			width: 100%;
			margin-right: 0px;
		}
	}

	.container {
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
	@media only screen and (min-width: 768px){
		.container {
			width: 750px;
		}
	}
	@media only screen and (min-width: 992px){
		.container {
			width: 970px;
		}
	}
	@media only screen and (min-width: 1200px){
		.container {
			width: 1170px;
		}
	}

























/*  */
