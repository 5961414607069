/* @font-face
{
  font-family: Gotham-Regular;
  src: url('../fonts/Gotham-Regular.otf');
}
*{
  font-family: 'Gotham-Regular';
} */
body
{
  /* overflow-y: hidden; */
}
.Toastify
{
  font-family: 'Gotham-Regular';
}
.header
{
  position: relative;
  float: left;
  width: 100%;
  height: 80px;
}
.header .header-inside
{
  position: relative;
  float: left;
  width: 100%;
  height: 80px;
}
.header .header-inside .logo
{
  position: relative;
  float: left;
  width: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s linear all;
}
.header .header-inside .logo img
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.header .header-inside .nav
{
  float: left;
  width: calc(100% - 150px);
  padding-left: 150px;
}
.header .header-inside .nav .nav-wrapper
{
  float: right;
  width: auto;
  height: 80px;
  display: flex;
  align-items: center;
}
.header .header-inside .nav .nav-wrapper a
{
  float: left;
  height: auto;
  width: auto;
  margin: 20px;
  color: #000047;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  transition: .3s linear all;
}
.header .header-inside .nav .nav-wrapper a.active,
.header .header-inside .nav .nav-wrapper a:hover
{
  font-weight: bold;
  color: #00a9ff;
}
.header .header-inside .nav .nav-wrapper a:last-child
{
  margin-right: 0px;
}
.background-color
{
  position: absolute;
  width: 160%;
  height: 1500px;
  background-color: red;
  background-color: #f9fafb;
  z-index: -1;
  transform: rotate(10deg);
  top: -850px;
  left: -500px;
}
.background-color-second
{
  position: absolute;
  width: 160%;
  height: 1600px;
  background-color: transparent;
  /* border-bottom: 1px solid #57c6ff; */
  /* background-color: #f9fafb; */
  /* z-index: -2; */
  transform: rotate(13deg);
  top: -700px;
  left: 515px;
}
.background-color-second:before,
.background-color-second:after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: -3px;
}
.background-color-second:after {
  right: -3px;
  height: 3px;
  background-image: -webkit-gradient(linear, 50 50, 100% 0, from(#fff), to(#fff));
  background-image: -webkit-linear-gradient(right, #57c6ff, #fff);
  /* background-image: -moz-linear-gradient(right, #000, transparent);
  background-image: -o-linear-gradient(right, #000, transparent); */
}
.body
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 80px;
}
.fifth-section,
.fourth-section,
.third-section,
.second-section,
.first-section
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.fifth-section-inside,
.fourth-section-inside,
.third-section-inside,
.second-section-inside,
.first-section-inside
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
/* test styles */
.first-section-inside .left-content
{
  position: relative;
  float: left;
  width: 40%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.left-content-work{
  margin-left: 50px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  float: left;
  width: 40%;
}
.first-section-inside .work-right{
  padding: 0;
  position: relative;
  float: left;
  width: 60%;
}
.first-section-inside .right-video
{
  position: relative;
  float: left;
  width: 60%;
  height: 500px;
  padding: 0px 0px 0px 50px;
}
.first-section-inside .right-video img,
.first-section-inside .work-right img
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.first-section-inside .right-video .video
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
span
{
  font-family: 'Montserrat', sans-serif;
}
.first-section-inside .left-content span,
.first-section-inside .left-content-work span
{
  float: left;
  width: 100%;
  height: auto;
  font-size: 46px;
}
.first-section-inside .left-content span:nth-child(2),
.first-section-inside .left-content-work span:nth-child(2)
{
  float: left;
  width: 100%;
  height: auto;
  font-size: 20px;
}
.partners
{
  position: relative;
  float: left;
  width: 100%;
  height: 100px;
  margin: 40px 0px;
}
.partners .img
{
  position: relative;
  float: left;
  width: calc(calc(100% / 5) - 40px);
  height: 90px;
  max-height: 80px;
  margin: 20px;
}
.partners .img:last-child
{
  margin-right: 0px;
}
.partners .img img
{
  object-fit: contain;
  object-position: center;
  left: 50%;
  position: relative;
  transform: translate(-50%, -50%);
  top: 50%;
}
.second-section
{
  margin-top: 100px;
}
.second-section .second-section-inside .title
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.second-section .second-section-inside .title span
{
    position: relative;
    float: left;
    width: auto;
    height: auto;
    font-size: 30px;
    font-weight: bold;
}
.second-section .second-section-inside .title span img
{
  margin-right: 20px;
}
.second-section .second-section-inside .title h1
{
  margin-top: 50px;
  float: left;
  width: 100%;
}
.second-section .second-section-inside .services
{
  display: flex;
  display: -webkit-flex;
  -webkit-align-items: flex-start;
  -moz-align-items: center;
  /* align-items: flex-start; */
  /* -webkit-justify-content: center; */
  /* justify-content: center; */
  flex-direction: row;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-content: flex-end;
  -moz-align-content: flex-end;
  align-content: flex-start;
  /* margin-left: -15px; */
}
.second-section .second-section-inside .services
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  border-bottom: 2px solid #e6e6e6;
}
.second-section .second-section-inside .services .services-inside
{
  height: auto;
}
.second-section .second-section-inside .services .services-inside .services-inside-img
{
  position: relative;
  float: left;
  width: 100%;
  height: 35%;
}
.services-inside.grid-3
{
  margin-bottom: 60px;
}
.second-section .second-section-inside .services .services-inside .services-inside-img img
{
  position: relative;
  float: left;
  width: 100px;
  height: auto;
  object-fit: contain;
  object-position: center;
  max-height: 90px;
}
.second-section .second-section-inside .services .services-inside .services-content
{
  position: relative;
  float: left;
  width: 100%;
  height: 65%;
}
.second-section .second-section-inside .services .services-inside .services-content span
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.second-section .second-section-inside .services .services-inside .services-content span:first-child
{
  font-weight: bold;
  font-size: 20px;
  margin: 15px 0px;
}
.second-section .second-section-inside .services .services-inside .services-content span:last-child
{
  font-size: 15px;
}
.third-section .third-section-inside .left-part
{
  height: auto;
}
.third-section .third-section-inside .left-part .title
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.third-section .third-section-inside .left-part .title span:first-child
{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    font-size: 30px;
    font-weight: bold;
}
.third-section .third-section-inside .left-part .title span img
{
  margin-right: 20px;
}
.third-section .third-section-inside .left-part .title h1
{
  margin-top: 50px;
  float: left;
  width: 55%;
  line-height: 55px;
}
.third-section .third-section-inside .left-part .title span
{
  float: left;
  width: 100%;
  margin: 20px 0px;
}
.third-section .third-section-inside .left-part .title a
{
  position: relative;
  float: left;
  width: 150px;
  height: 40px;
  background-color: #00a9ff;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.third-section .third-section-inside .right-part
{
  height: auto;
  transition: .3s linear all;
}
.third-section .third-section-inside .grid-2.right-part
{
  max-height: 800px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.third-section .third-section-inside .right-part .testimonials
{
  position: relative;
  float: right;
  width: 90%;
  height: 250px;
  padding: 40px;
  background-color: #f6f6f6;
  margin-bottom: 30px;
}
.third-section .third-section-inside .right-part .testimonials .quotes
{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
}
.third-section .third-section-inside .right-part .testimonials .quotes img
{
  object-fit: contain;
  object-position: center;
  width: 35px;
  height: 35px;
}
.third-section .third-section-inside .right-part .testimonials .description
{
  position: relative;
  float: left;
  width: 70%;
  margin: 15px 0px;
  height: auto;
  max-height: 60px;
  overflow: hidden;
}
.third-section .third-section-inside .right-part .testimonials .description span
{
  float: left;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; 
}
.third-section .third-section-inside .right-part .testimonials .name
{
  position: relative;
  float: left;
  width: 70%;
}
.third-section .third-section-inside .right-part .testimonials .name span
{
  float: left;
  width: 100%;
  font-size: 19px;
  font-weight: bold;
}
.third-section .third-section-inside .right-part .testimonials .img
{
  position: relative;
  float: right;
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.third-section .third-section-inside .right-part .testimonials .img img
{
  position: relative;
  float: left;
  width: 90px;
  height: 90px;
  /* border: 2px solid red; */
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}



.fourth-section .fourth-section-inside .title
{
  position: absolute;
  float: left;
  width: 100%;
  height: auto;
  margin-top: -120px;
}
.fourth-section .fourth-section-inside .title span:first-child
{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    font-size: 30px;
    font-weight: bold;
}
.fourth-section .fourth-section-inside .title span img
{
  margin-right: 20px;
}
.fourth-section .fourth-section-inside .title h1
{
  margin-top: 50px;
  float: left;
  width: 40%;
  line-height: 55px;
}
.fourth-section .fourth-section-inside .projects
{
  position: relative;
  width: 100%;
  height: auto;
  /* background-color: red; */
  margin-top: 130px;
  margin-bottom: 20px;
  border-radius: 10px;
  position: relative;
  display: block;
  overflow: hidden;
  /* margin: 0;
  padding: 0; */
}
.fourth-section .fourth-section-inside .projects .slider-projcets
{
  position: relative;
  width: 4800px;
  height: 100%;
  overflow: hidden;
}
.fourth-section .fourth-section-inside .projects .projects-img
{
  position: relative;
  float: left;
  width: 100%;
  height: 600px;
  /* background-color: red; */
  margin-bottom: 20px;
  border-radius: 10px;
}
.fourth-section .fourth-section-inside .projects .projects-img .projects-img-inside img
{
  /* width: 100%;
  height: 100%; */
  object-fit: contain;
  object-position: center;
}


.fifth-section .fifth-section-inside .title
{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
}
.fifth-section .fifth-section-inside .title span:first-child
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 30px;
  font-weight: bold;
}
.fifth-section .fifth-section-inside .title span img
{
  margin-right: 20px;
}
.fifth-section .fifth-section-inside .title h1
{
  margin-top: 25px;
  float: left;
  width: 100%;
  line-height: 55px;
}
.fifth-section .fifth-section-inside .title span
{
  float: left;
  width: 90%;
  margin: 20px 0px;
}
.fifth-section .fifth-section-inside .title a
{
  position: relative;
  float: left;
  width: 150px;
  height: 40px;
  background-color: #00a9ff;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 50px 0px;
}
.fifth-section .fifth-section-inside .team
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.fifth-section .fifth-section-inside .team .team-inside
{
  position: relative;
  float: left;
  height: 400px;
  margin-bottom: 40px;
}
.fifth-section .fifth-section-inside .team .team-inside .team-img
{
  position: relative;
  float: left;
  width: 100%;
  height: 85%;
}
.fifth-section .fifth-section-inside .team .team-inside .team-img img
{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.fifth-section .fifth-section-inside .team .team-inside .team-content
{
  position: relative;
  float: left;
  width: 100%;
  height: 15%;
}
.fifth-section .fifth-section-inside .team .team-inside .team-content span:first-child
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}
.fifth-section .fifth-section-inside .team .team-inside .team-content span:last-child
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 14px;
}
.sixth-section
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: #f6f6f6;
  padding: 60px 0px;
}
.sixth-section .sixth-section-inside
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
.sixth-section .sixth-section-inside .left-part
{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
}
.sixth-section .sixth-section-inside .left-part h1
{
  float: left;
  width: 100%;
  margin: 10px 0px;
}
.sixth-section .sixth-section-inside .left-part span
{
  float: left;
  width: 100%;
  font-size: 15px;
  margin: 10px 0px;
}
.sixth-section .sixth-section-inside .right-part
{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
}

.sixth-section .sixth-section-inside .right-part a:first-child
{
  position: relative;
  float: left;
  width: 150px;
  height: 40px;
  background-color: transparent;
  text-transform: uppercase;
  border: 1px solid #00a9ff;
  color: #00a9ff;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 30px 50px 0px;
  transition: .3s linear all;
}
.sixth-section .sixth-section-inside .right-part a:first-child:hover
{
  background-color: #00a9ff;
  color: #fff;
}
.sixth-section .sixth-section-inside .right-part a:last-child:hover
{
  background-color: transparent;
  color: #00a9ff;
  border: 1px solid #00a9ff;
}
.sixth-section .sixth-section-inside .right-part a:last-child
{
  position: relative;
  float: left;
  width: 150px;
  height: 40px;
  background-color: #00a9ff;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 50px 0px;
  transition: .3s linear all;
}
.footer
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.footer .footer-inside
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  padding: 80px 0px 40px 0px;
  border-bottom: 2px solid #e6e6e6;
}
.footer .footer-inside .left-part
{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
}
.footer .footer-inside .right-part
{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
}
.footer .footer-inside .right-part .footer-services
{
  position: relative;
  float: left;
  height: 100%;
}
.footer .footer-inside .right-part .footer-services span
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.footer .footer-inside .right-part .footer-services a
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 16px;
  line-height: 35px;
  color: #111;
}
.footer .footer-copyright
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  padding: 20px 0px;
}
.footer .footer-copyright span:first-child
{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
  text-align: left;
}
.footer .footer-copyright span:last-child
{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
  text-align: right;
}
.flex-direction-nav
{
  display: none;
}
.flex-control-thumbs
{
  padding: 0px 50px !important;
}
/* #00a9ff  */
.flex-control-thumbs li
{
  width: calc(20% - 10px) !important;
  float: left !important;
  margin-left: 10px !important;
  /* height: 3px !important; */
  /* background-color: #e6e6e6 !important; */
}
/* .flex-control-thumbs img,
.flex-control-thumbs .flex-active
{
  opacity: 0 !important;
}
.flex-control-thumbs li .flex-active
{
  background-color: #00a9ff !important;
} */






/* ABOUT US */
/* ABOUT US */
/* ABOUT US */
/* ABOUT US */
/* ABOUT US */
/* ABOUT US */
/* ABOUT US */

.about-title
{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}
.about-title h1
{
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  color: #000047;
}
.about-title .links
{
  width: 100%;
  display: flex;
  justify-content: center;
}
.about-title .links span
{
  font-size: 17px;
  margin: 10px;
  color: #433c4d;
  cursor: pointer;
}
.about-title .links span.active,
.about-title .links span:hover
{
  color: #00a9ff;
  font-weight: bold;
}
.first-section.about-first .left-content,
.first-section.about-first .right-video,
.first-section.about-second .left-content,
.first-section.about-second .right-video,
.first-section.about-third .left-content,
.first-section.about-third .right-video
{
  width: 50%;
}
.first-section.about-third,
.first-section.about-second
{
  margin-top: 100px;
}
.first-section.about-second .first-section-inside
{
  display: flex;
  flex-direction: row-reverse;
}

.first-section.about-first .first-section-inside .left-content span:nth-child(1),
.first-section.about-second .first-section-inside .left-content span:nth-child(1),
.first-section.about-third .first-section-inside .left-content span:nth-child(1)
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 25px;
  font-weight: bold;
}
.first-section.about-first .first-section-inside .left-content span:nth-child(1) img,
.first-section.about-second .first-section-inside .left-content span:nth-child(1) img,
.first-section.about-third .first-section-inside .left-content span:nth-child(1) img
{
  margin-right: 20px;
}
.first-section.about-first .first-section-inside .left-content span:nth-child(2),
.first-section.about-second .first-section-inside .left-content span:nth-child(2),
.first-section.about-third .first-section-inside .left-content span:nth-child(2)
{
  position: relative;
  float: left;
  width: 90%;
  height: auto;
  font-size: 18px;
  margin-top: 40px;
}

.first-section.about-third .first-section-inside .left-content span:nth-child(3)
{
  position: relative;
  float: left;
  width: 90%;
  height: auto;
  font-size: 18px;
  margin-top: 40px;
}
.first-section.about-third .left-content,
.first-section.about-third .right-video
{
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.first-section.about-third .first-section-inside .right-video span:nth-child(1)
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 25px;
  font-weight: bold;
  opacity: 0;
  z-index: -1;
}
.first-section.about-third .first-section-inside .right-video span:nth-child(1) img
{
  margin-right: 20px;
  width: auto;
  height: auto;
}
.first-section.about-third .first-section-inside .right-video span:nth-child(2)
{
  position: relative;
  float: left;
  width: 90%;
  height: auto;
  font-size: 18px;
  margin-top: 40px;
}
.first-section.about-third .first-section-inside .right-video span:nth-child(3)
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 18px;
  margin-top: 40px;
  opacity: 0;
  z-index: -1;
}
.extra-content
{
  position: relative;
  float: left;
  width: 90%;
  height: auto;
}
.extra-content .img-extra
{
  position: relative;
  float: left;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}
.extra-content .img-extra img
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.extra-content .content-extra
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
.extra-content .content-extra span
{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.about .first-section-inside .right-video img
{
  width: 90%;
}

/* COMMUNITY */
/* COMMUNITY */
/* COMMUNITY */
/* COMMUNITY */
/* COMMUNITY */
/* COMMUNITY */
/* COMMUNITY */
/* COMMUNITY */

.community .first-section .about-title .text
{
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
}
.community .first-section .about-title .text span
{
  position: relative;
  float: left;
  width: 50%;
  margin-top: 40px;
  font-size: 18px;
}
.community .first-section .first-section-inside .left-content
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin: 80px 0px;
}
.community .first-section .first-section-inside .left-content span:nth-child(1)
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 25px;
  font-weight: bold;
  color: #000047;
}
.community .first-section .first-section-inside .left-content span img:nth-child(1)
{
  margin-right: 20px;
}
.community .first-section .first-section-inside .left-content h1
{
  margin-top: 40px;
  float: left;
  width: 50%;
  line-height: 45px;
  color: #000047;
}
.community .first-section .first-section-inside .text-community
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  display: -webkit-flex;
  -webkit-align-items: flex-start;
  -moz-align-items: center;
  /* align-items: flex-start; */
  /* -webkit-justify-content: center; */
  /* justify-content: center; */
  flex-direction: row;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-content: flex-end;
  -moz-align-content: flex-end;
  align-content: flex-start;
}
.community .first-section .first-section-inside .text-community span
{
  position: relative;
  float: left;
  width: 48%;
  height: auto;
  margin-right: 2%;
  margin-bottom: 40px;
  font-size: 18px;
}
.community .first-section .first-section-inside .grid.boxes-community
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 30px;


  display: -webkit-flex;
  -webkit-align-items: flex-start;
  -moz-align-items: center;
  /* align-items: flex-start; */
  /* -webkit-justify-content: center; */
  /* justify-content: center; */
  flex-direction: row;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-content: flex-end;
  -moz-align-content: flex-end;
  align-content: flex-start;
  /* margin-left: -15px; */
}
.community .first-section .first-section-inside .grid.boxes-community .box
{
  height: 550px;
  padding: 50px 0px;
  border: 1px solid #94d6f8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: .3s linear all;
  transform: scaleY(0.9);

}
.community .first-section .first-section-inside .grid.boxes-community .box:hover
{
  transform: scaleY(1.1);
  border: none;
  box-shadow: 0px 0px 36px -15px rgba(0,0,0,.3);
}
.community .first-section .first-section-inside .grid.boxes-community .box .logo
{
  position: relative;
  float: left;
  width: 80px;
  height: 80px;
}
.community .first-section .first-section-inside .grid.boxes-community .box .logo img
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.community .first-section .first-section-inside .grid.boxes-community .box .title
{
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  height: auto;
  padding: 20px 0px;
}
.community .first-section .first-section-inside .grid.boxes-community .box .title span
{
  font-weight: bold;
  color: #000047;
  font-size: 18px;
}
.community .first-section .first-section-inside .grid.boxes-community .box .description
{
  position: relative;
  float: left;
  width: 70%;
  text-align: center;
  height: auto;
}
.community .first-section .first-section-inside .grid.boxes-community .box .description span
{
  font-size: 18px;
}
.community .first-section .first-section-inside .grid.boxes-community .box .description button
{
  margin: 30px 0px;
  width: 170px;
  height: 0px;
  border: none;
  background-color: #00a9ff;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  transition: .3s linear all;
  overflow: hidden;
}
.community .first-section .first-section-inside .grid.boxes-community .box:hover .description button
{
  height: 40px;
}

/* CONTACT US */
/* CONTACT US */
/* CONTACT US */
/* CONTACT US */
/* CONTACT US */
/* CONTACT US */

.contact .contact-form
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 80px;
  max-height: 500px;
}
.contact .contact-form .left-part
{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
  padding: 20px;
}
.contact .contact-form .left-part p
{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  font-size: 30px;
  color: #000047;
}
.contact .contact-form .left-part .contact-content
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 50px;
}
.contact .contact-form .left-part .contact-content h1
{
  position: relative;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  color: #000047;
}
.contact .contact-form .left-part .contact-content span
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 15px;
}
.contact .contact-form .left-part .contact-content span:nth-of-type(1),
.contact .contact-form .left-part .contact-content span:nth-of-type(3)
{
  color: #4b4454;
  margin-top: 20px;
}
.contact .contact-form .left-part .contact-content span:nth-of-type(2),
.contact .contact-form .left-part .contact-content span:nth-of-type(4)
{
  color: #000047;
  margin-top: 5px;
}
.contact .contact-form .right-part
{
  position: relative;
  float: left;
  width: 50%;
  height: 500px;
  padding: 20px;
}
.contact .contact-form .right-part form
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
.contact .contact-form .right-part form .input-name
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.contact .contact-form .right-part form .input-subject,
.contact .contact-form .right-part form .input-email,
.contact .contact-form .right-part form .input-message
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 20px;
  color: #433c4d;
}
.contact .contact-form .right-part form .input-name span,
.contact .contact-form .right-part form .input-subject span,
.contact .contact-form .right-part form .input-message span,
.contact .contact-form .right-part form .input-email span
{
  position: relative;
  float: left;
  width: 100%;
  font-size: 17px;
  color: #433c4d;
  z-index: 9;
}
.contact .contact-form .right-part form .input-message textarea
{
  position: relative;
  float: left;
  width: 100%;
  border: none;
  border-bottom: 1px solid #d8d8d8;
  resize: none;
  color: #433c4d;
}
.contact .contact-form .right-part form .input-name input,
.contact .contact-form .right-part form .input-subject input,
.contact .contact-form .right-part form .input-email input
{
  position: relative;
  float: left;
  width: 100%;
  border: none;
  border-bottom: 1px solid #d8d8d8;
  resize: none;
  color: #433c4d;
  height: 30px;
}
.contact .contact-form .right-part form button
{
  position: relative;
  float: left;
  width: 150px;
  height: 40px;
  background-color: #00a9ff;
  border: none;
  margin-top: 30px;
  color: #fff;
  font-size: 15px;
}

/*  TEAM */
/*  TEAM */
/*  TEAM */
/*  TEAM */
/*  TEAM */
/*  TEAM */
.team-second-section
{
  position: relative;
  float: left;
  width: 100%;
  height: 300px;
  /* background-image: url('../img/office.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: 9;
}
.overlay
{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(7,7,56, .7);
  /* z-index: 10; */
}
.team-content-second
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('./../img/2.jpg');
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.team-content-second h1
{
  color: #fff;
  font-size: 35px;
}
.team-content-second span
{
  color: #fff;
  font-size: 17px;
  margin: 15px 0px;
  text-align: center;
}
span.bold-txt {
  font-size: 49px;
  font-weight: bold;
}
.team-third-section
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.team-third-section .left-content
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin: 50px 0px;
}
.team-third-section .left-content span
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 25px;
  font-weight: bold;
  color: #000047;
}
.team-third-section .left-content h1
{
  margin-top: 40px;
  float: left;
  width: 70%;
  line-height: 45px;
  color: #000047;
}
.team-third-section .grid.team-boxes
{
  height: auto;
}
.team-third-section .grid.team-boxes .grid-4
{
  height: auto;
}
.team-third-section .grid.team-boxes .grid-4 .team-img
{
  position: relative;
  float: left;
  width: 100%;
  height: 350px;
}
.team-third-section .grid.team-boxes .grid-4 .team-img img
{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.team-third-section .grid.team-boxes .grid-4 .team-box-content
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.team-third-section .grid.team-boxes .grid-4 .team-box-content span:nth-child(1)
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}
.team-third-section .grid.team-boxes .grid-4 .team-box-content span:nth-child(2)
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 14px;
}


/* SERVICES */
/* SERVICES */
/* SERVICES */
/* SERVICES */
/* SERVICES */
/* SERVICES */
/* SERVICES */

.after-title
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.after-title span
{
  width: 50%;
  text-align: center;
}
.services-part
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.service-one,
.service-two,
.service-three,
.service-four,
.service-five
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin: 50px 0px;
  display: flex;
}
.service-one.reverse,
.service-two.reverse,
.service-three.reverse,
.service-four.reverse,
.service-five.reverse
{
  display: flex;
  flex-direction: row-reverse;
}
.service-one .part-one,
.service-two .part-one,
.service-three .part-one,
.service-four .part-one,
.service-five .part-one
{
  position: relative;
  float: left;
  width: 70%;
  height: auto;
}
.service-one .part-one h1,
.service-two .part-one h1,
.service-three .part-one h1,
.service-four .part-one h1,
.service-five .part-one h1
{
    position: relative;
    float: left;
    width: 100%;
    font-size: 25px;
    color: #000047;
}
.service-one .part-one span,
.service-two .part-one span,
.service-three .part-one span,
.service-four .part-one span,
.service-five .part-one span
{
  position: relative;
  float: left;
  width: 100%;
  font-size: 16px;
  color: #433c4d;
  margin: 20px 0px;
}
.service-one .part-one span:nth-of-type(2),
.service-two .part-one span:nth-of-type(2),
.service-three .part-one span:nth-of-type(2),
.service-four .part-one span:nth-of-type(2),
.service-five .part-one span:nth-of-type(2)
{
  margin: 0px;
}
.service-one .part-two,
.service-two .part-two,
.service-three .part-two,
.service-four .part-two,
.service-five .part-two
{
  position: relative;
  float: left;
  width: 30%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-one .part-two img,
.service-two .part-two img,
.service-three .part-two img,
.service-four .part-two img,
.service-five .part-two img
{
  width: 150px;
  height: 150px;
  object-fit: contain;
  object-position: center;
}
/** OUR WORK ****/
.work-title
{
  clear: both;
  padding: 30px;
}
.first-work,
.second-work
{
  clear: both;
  display: flex;
  margin-bottom: 30px;
  margin-top: 20px;
}
.first-work-content 
{
  width: 40%;
  padding: 0 40px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.second-work-content  
{
  width: 40%;
  padding: 0 0 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.first-work-content span,
.second-work-content span
{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 30px;
}
.first-work-content p,
.second-work-content p
{
  font-size: 18px;
  line-height: 1.5;
  color: #433c4d;
}
.first-work-img,
.second-work-img
{
  width: 60%;
  padding: 20px;
}